import {StrictMode} from "react";
import ReactDOM from "react-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import App from "./App";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";

const rootElement = document.getElementById("root");
ReactDOM.render(
    <StrictMode>
        <Router>
            <Switch>
                <Route exact path="/">
                    <App/>
                </Route>
                <Route path="/privacy-policy">
                    <PrivacyPolicy/>
                </Route>
                <Route path="/terms-and-conditions">
                    <TermsAndConditions/>
                </Route>
            </Switch>
        </Router>
    </StrictMode>,
    rootElement
);
