import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function TermsAndConditions() {
    return (
        <div className="App m-4">
            <Card>
                <Card.Body>
                    <p>
                        <b>Terms of Service</b>
                    </p>
                    <p>
                        <b>
                            Last modified October 24, 2021 with an effective date of October 27,
                            2021
                        </b>
                    </p>

                    <p>
                        Welcome, and thank you for your interest in Beyond Budget. (“Beyond
                        Budget,” “we”, or “us”). and our website at zero-budget.net, along
                        with our related websites, networks, applications, mobile
                        applications, and other services provided by us as defined below,
                        and collectively, the “Service”. These Terms of Service are a
                        legally binding contract between you and Beyond Budget regarding your
                        use of the Service.
                    </p>

                    <p>
                        <b>PLEASE READ THE FOLLOWING TERMS CAREFULLY.</b>
                    </p>

                    <p>
                        BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE
                        ACCESSING OR USING THE SERVICE, YOU AGREE THAT YOU HAVE READ AND
                        UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE, YOU
                        AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING
                        BEYOND BUDGET PRIVACY POLICY (TOGETHER, THESE “TERMS''). If you are
                        not eligible, or do not agree to the Terms, then you do not have our
                        permission to use the Service. YOUR USE OF THE SERVICE, AND BEYOND
                        BUDGET’S PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT
                        BY BEYOND BUDGET AND BY YOU TO BE BOUND BY THESE TERMS.
                    </p>

                    <p>
                        Arbitration NOTICE. Except for certain kinds of disputes described
                        in Section 18, you agree that disputes arising under these Terms
                        will be resolved by binding, individual arbitration, and BY
                        ACCEPTING THESE TERMS, YOU AND BEYOND BUDGET ARE EACH WAIVING THE
                        RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR
                        REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO
                        COURT to assert or defend your rights under this contract (except
                        for matters that may be taken to small claims court). Your rights
                        will be determined by a neutral arbitrator and not a judge or jury.
                        (<b>See Section 18.</b>)
                    </p>

                    <p>
                        <b>1. Beyond Budget Service Overview</b>
                    </p>
                    <p>
                        Beyond Budget offers users budget management software services which
                        allow users to organize their financial matters in one place.
                    </p>

                    <p>
                        <b>2. Eligibility</b>
                    </p>
                    <p>
                        You must be at least 13 years old to use the Service. By agreeing to
                        these Terms, you represent and warrant to us that: (a) you are at
                        least 13 years old; (b) you have not previously been suspended or
                        removed from the Service; and (c) your registration and your use of
                        the Service is in compliance with any and all applicable laws and
                        regulations. If you are an entity, organization, or company, the
                        individual accepting these Terms on your behalf represents and
                        warrants that they have authority to bind you to these Terms and you
                        agree to be bound by these Terms.
                    </p>

                    <p>
                        <b>3. Accounts and Registration</b>
                    </p>
                    <p>
                        To access the Service, you must register for an account. When you
                        register for an account, you may be required to provide us with some
                        information about yourself, such as your name, email address, or
                        other contact information. You agree that the information you
                        provide to us is accurate and that you will keep it accurate and
                        up-to-date at all times. When you register, you may be asked to
                        provide a password. You are solely responsible for maintaining the
                        confidentiality of your account and password, and you accept
                        responsibility for all activities that occur under your account. If
                        you believe that your account is no longer secure, then you must
                        immediately emailing us at admin@zero-budget.net.
                    </p>

                    <p>
                        <b>4. General Payment Terms</b>
                    </p>
                    <p>
                        To continue to use the app after the trial period you will need to
                        pay fees. Before you pay any fees, you will have an opportunity to
                        review and accept the fees that you will be charged. All fees are in
                        the currencies provided on the Beyond Budget app and, except as
                        expressly provided by applicable law, are non-refundable.
                    </p>

                    <p>
                        1 Price: Beyond Budget reserves the right to determine pricing for the
                        Service. Beyond Budget will make reasonable efforts to keep pricing
                        information published on the app up to date. We encourage you to
                        check our app periodically for current pricing information. Beyond
                        Budget may change the price of any feature of the Service; if this
                        occurs, we will provide you advance notice of the changes before
                        they apply. Beyond Budget, at its sole discretion, may make
                        promotional offers with different features and different pricing to
                        any of Beyond Budget’s customers. These promotional offers, unless
                        made to you, will not apply to your offer or these Terms.
                    </p>

                    <p>
                        2 Authorisation: You authorise Beyond Budget and its third party
                        payment processors to charge all sums for the orders that you make
                        and any level of Service you select as described in these Terms or
                        published by Beyond Budget, including all applicable taxes, to the
                        payment method specified in your account. If you pay any fees with a
                        credit card, Beyond Budget or its third party payment processors may
                        seek pre-authorisation of your credit card account prior to your
                        purchase to verify that the credit card is valid and has the
                        necessary funds or credit available to cover your purchase.
                    </p>

                    <p>
                        3 Subscription Service. The Service includes automatically recurring
                        payments for periodic charges (“Subscription Service”). If you
                        activate a Subscription Service, you authorise Beyond Budget or its
                        third party payment processors to periodically charge, on a
                        going-forward basis and until cancellation of either the recurring
                        payments or your account, all accrued sums on or before the payment
                        due date for the accrued sums. The “Subscription Billing Date” is
                        the date when you purchase your first subscription to the Service.
                        For information on the “Subscription Fee”, please see our pricing
                        page. Your account will be charged automatically on the Subscription
                        Billing Date all applicable fees and taxes for the next subscription
                        period (e.g., monthly billing periods will be billed on the same day
                        each month). The subscription will continue unless and until you
                        cancel your subscription or we terminate it. You must cancel your
                        subscription before it renews in order to avoid billing of the next
                        periodic Subscription Fee to your account. Beyond Budget or its third
                        party payment processor will bill the periodic Subscription Fee to
                        the payment method you provide to us during registration. (or to a
                        different payment method if you change your payment information).
                        You may cancel the Subscription Service by following the
                        instructions provided at the following links for Beyond Budget app.
                    </p>

                    <p>
                        4 Delinquent Accounts. Beyond Budget may suspend or terminate access
                        to the Service, including fee-based portions of the Service, for any
                        account for which any amount is due but unpaid. In addition to the
                        amount due for the Service, a delinquent account will be charged
                        with fees or charges that are incidental to any chargeback or
                        collection of any unpaid amount, including collection fees.
                    </p>

                    <p>
                        <b>5. Beyond Budget Service Products</b>
                    </p>
                    <p>
                        You may choose to sign up for the trial version of Beyond Budget and
                        then pay for the subscription, which automatically renews at the end
                        of your billing period consistent with the terms of Section 4.3.
                    </p>

                    <p>
                        If Beyond Budget offers you a free trial of Beyond Budget (“Trial
                        Period”), you will have access to most of Beyond Budget features
                        during the Trial Period. If you do not elect to upgrade to a paid
                        subscription prior to the end of the Trial Period, the main features
                        will become unavailable to you. You will be charged with the amount
                        shown on the Pricing Page before you can access Beyond Budget. All
                        prices shown on the Pricing Page are inclusive of any applicable
                        sales taxes, levies, value-added taxes, or duties imposed by taxing
                        authorities, and you are responsible for the forwarding/remittance
                        of all of those taxes, levies, or duties (except for E.U. Customers,
                        where the pricing includes mandatory E.U. VAT charges).
                    </p>

                    <p>
                        <b>6. Licenses</b>
                    </p>
                    <p>
                        Limited License. Subject to your complete and ongoing compliance
                        with these Terms, Beyond Budget grants you, solely for your
                        personal/family use, or for your employer’s internal business
                        operations, as applicable, a limited, non-exclusive,
                        non-transferable, non-sublicensable, revocable license to access and
                        use the Service to which you have subscribed, including the right
                        for to install and use a reasonable number of object code copies of
                        any mobile application associated with the Service, obtained from a
                        legitimate marketplace on a mobile device that, as applicable,
                        either you own or control, or your employer owns or controls.
                    </p>

                    <p>
                        License Restrictions. Except and solely to the extent such a
                        restriction is impermissible under applicable law, you may not: (a)
                        reproduce, distribute, publicly display, or publicly perform the
                        Service; (b) make modifications to the Service; or (c) interfere
                        with or circumvent any feature of the Service, including any
                        security or access control mechanism. If you are prohibited under
                        applicable law from using the Service, you may not use it.
                    </p>

                    <p>
                        Feedback. If you choose to provide input and suggestions regarding
                        problems with or proposed modifications or improvements to the
                        Service (“Feedback”), then you hereby grant Beyond Budget an
                        unrestricted, perpetual, irrevocable, non-exclusive, fully-paid,
                        royalty-free right to exploit the Feedback in any manner and for any
                        purpose, including to improve the Service and create other products
                        and services.
                    </p>

                    <p>
                        <b>7. Ownership; Proprietary Rights</b>
                    </p>
                    <p>
                        The Service is owned and operated by Beyond Budget. The visual
                        interfaces, graphics, design, compilation, information, data,
                        computer code (including source code or object code), products,
                        software, services, promotional content and all other elements of
                        the Service (“Materials”) provided by Beyond Budget are protected by
                        intellectual property and other laws. All Materials included in the
                        Service are the property of Beyond Budget or its third party
                        licensors. Except as expressly authorised by Beyond Budget, you may
                        not make use of the Materials. Beyond Budget reserves all rights to
                        the Materials not granted expressly in these Terms.
                    </p>

                    <p>
                        <b>8. Third Party Terms</b>
                    </p>
                    <p>
                        Third Party Services and Linked Websites. Beyond Budget may provide
                        tools through the Service that enable you to export information,
                        including User Content, to third party services, including through
                        features that allow you to link your account on Beyond Budget with an
                        account on the third party service. By using one of these tools, you
                        agree that Beyond Budget may transfer that information to the
                        applicable third party service. Third party services are not under
                        Beyond Budget’s control, and, to the fullest extent permitted by law,
                        Beyond Budget is not responsible for any third party service’s use of
                        your exported information. The Service may also contain links to
                        third party websites. Linked websites are not under Beyond Budget’s
                        control, and Beyond Budget is not responsible for their content.
                    </p>

                    <p>
                        Third Party Software. The Service may include or incorporate third
                        party software components that are generally available free of
                        charge under licenses granting recipients broad rights to copy,
                        modify, and distribute those components (“Third Party Components”).
                        Although the Service is provided to you subject to these Terms,
                        nothing in these Terms prevents, restricts, or is intended to
                        prevent or restrict you from obtaining Third Party Components under
                        the applicable third party licenses or to limit your use of Third
                        Party Components under those third party licenses.
                    </p>

                    <p>8.1 Third-Party Account Synchronization Technology</p>
                    <p>
                        Beyond Budget uses technology and services provided by third-party service providers appointed by
                        Beyond Budget from time to time, including Akahu Technologies Limited and any successor entity or
                        assignee and/or any replacement or successor service provider. You can find applicable terms and
                        conditions and details on privacy and terms of use below. Those services are used to
                        enable:
                    </p>

                    <p>a. New Zealand bank accounts to be linked with Beyond Budget's App;
                        b. Transactions from your linked bank accounts to be made visible via the Beyond Budget App, and
                        c. Beyond Budget to perform other obligations to you (including where Beyond Budget is acting as
                        your agent under these Terms and Conditions).</p>

                    <p>To the extent permitted by law, no Third Party has any obligation, duty or liability to you
                        under these Terms and Conditions or otherwise in connection with your Beyond Budget App. You must
                        not bring any claim under or in connection with these Terms and Conditions or otherwise in
                        connection with your Beyond Budget App against a Third Party. You indemnify each Third Party for
                        any losses, damages, costs or other liabilities Beyond Budget Terms of Use suffered by each Third
                        Party or any of its related companies in connection with any such claim. If, notwithstanding the
                        foregoing, a Third Party is found liable to you for any reason, to the extent permitted by
                        law:</p>

                    <p>a. the disclaimers, limitations and exclusions of liability in these Terms and Conditions also
                        apply in respect of any liability a Third Party may have to you in whether in contract, equity,
                        statute or tort (including negligence) or otherwise; and
                        b. the Third Party's liability to you, whether in contract, equity, statute or tort (including
                        negligence) or otherwise, will be limited in aggregate to NZ$100.</p>

                    <p>All right, title and interest in and to the Third Party's technology or any improvements or
                        variations, including copyright and other intellectual property rights as well as all
                        de-identified uploaded information, belong to the Third Party and/or its licensors. You must not
                        copy, reverse engineer, decompile, disassemble, attempt to derive the source code of, modify,
                        create derivative works of, re-post to other websites, frame, deep-link to, change, or otherwise
                        distribute, license, sub-license or transfer in any form any aspect of the Third Party's
                        technology without the Third Party's prior written consent.</p>

                    <p>Nothing in these Terms and Conditions or otherwise in connection with your Beyond Budget App
                        creates a contract between you and any Third Party, nor any obligation, duty or liability of a
                        Third Party to you. However, you agree that each Third Party is a third party beneficiary of,
                        and may enforce, these Terms and Conditions.</p>


                    <p>
                        Akahu end user terms - <Link to={{pathname: "https://www.akahu.io/end-user-terms"}}
                                                     target="_blank">https://www.akahu.io/end-user-terms</Link>
                    </p>

                    <p>
                        Akahu privacy notice - <Link to={{pathname: "https://www.akahu.io/privacy-notice"}}
                                                     target="_blank">https://www.akahu.io/privacy-notice/</Link>
                    </p>

                    <p>
                        <b>9. User Content</b>
                    </p>
                    <p>
                        User Content Generally. Certain features of the Service may permit
                        users to upload content to the Service and send content through the
                        Service, including messages, reviews, photos, audio, video, images,
                        folders, data, text, graphics, and other types of information,
                        material, and works (“User Content”) and to publish User Content on
                        the Service. You retain any copyright and other proprietary rights
                        that you may hold in the User Content that you post to the Service.
                    </p>

                    <p>
                        Limited License Grant to Beyond Budget. By providing User Content to
                        or via the Service, you grant Beyond Budget a worldwide,
                        non-exclusive, irrevocable, royalty-free, fully paid right and
                        license (with the right to sublicense) to host, store, transfer,
                        display, perform, reproduce, modify for the purpose of formatting
                        for display, and distribute your User Content, in whole or in part,
                        in any media formats and through any media channels now known or
                        hereafter developed.
                    </p>

                    <p>
                        Limited License Grant to Other Users. By providing User Content to
                        or via the Service to other users of the Service, you grant those
                        users a non-exclusive license to access and use that User Content as
                        permitted by these Terms and the functionality of the Service. User
                        Content Representations and Warranties. Beyond Budget disclaims any
                        and all liability in connection with User Content. You are solely
                        responsible for your User Content and the consequences of providing
                        User Content via the Service. By providing User Content via the
                        Service, you affirm, represent, and warrant that: you are the
                        creator and owner of the User Content, or have the necessary
                        licenses, rights, consents, and permissions to authorise Beyond Budget
                        and users of the Service to use and distribute your User Content as
                        necessary to exercise the licenses granted by you in this Section,
                        in the manner contemplated by Beyond Budget, the Service, and these
                        Terms; your User Content, and the use of your User Content as
                        contemplated by these Terms, does not and will not: (i) infringe,
                        violate, or misappropriate any third party right, including any
                        copyright, trademark, patent, trade secret, moral right, privacy
                        right, right of publicity, or any other intellectual property or
                        proprietary right; (ii) slander, defame, libel, or invade the right
                        of privacy, publicity or other property rights of any other person;
                        or (iii) cause Beyond Budget to violate any law or regulation; and
                        your User Content could not be deemed by a reasonable person to be
                        objectionable, profane, indecent, pornographic, harassing,
                        threatening, embarrassing, hateful, or otherwise inappropriate.
                    </p>

                    <p>
                        User Content Disclaimer. We are under no obligation to edit or
                        control User Content that you or other users post or publish, and
                        will not be in any way responsible or liable for User Content. Beyond
                        Budget may, however, at any time and without prior notice, screen,
                        remove, edit, or block any User Content that in our sole judgment
                        violates these Terms or is otherwise objectionable. You understand
                        that when using the Service you will be exposed to User Content from
                        a variety of sources and acknowledge that User Content may be
                        inaccurate, offensive, indecent, or objectionable. You agree to
                        waive, and do waive, any legal or equitable right or remedy you have
                        or may have against Beyond Budget with respect to User Content. If
                        notified by a user or content owner that User Content allegedly does
                        not conform to these Terms, we may investigate the allegation and
                        determine in our sole discretion whether to remove the User Content,
                        which we reserve the right to do at any time and without notice. For
                        clarity, Beyond Budget does not permit copyright-infringing activities
                        on the Service.
                    </p>

                    <p>
                        Monitoring Content. Beyond Budget does not control and does not have
                        any obligation to monitor: (a) User Content; (b) any content made
                        available by third parties; or (c) the use of the Service by its
                        users. You acknowledge and agree that Beyond Budget reserves the right
                        to, and may from time to time, monitor any and all information
                        transmitted or received through the Service for operational and
                        other purposes. If at any time Beyond Budget chooses to monitor the
                        content, Beyond Budget still assumes no responsibility or liability
                        for content or any loss or damage incurred as a result of the use of
                        content. During monitoring, information may be examined, recorded,
                        copied, and used in accordance with our Privacy Policy.
                    </p>

                    <p>
                        <b>10. Communications</b>
                    </p>
                    <p>
                        Push Notifications. When you install our app on your mobile device,
                        you agree to receive push notifications, which are messages an app
                        sends you on your mobile device when the app is not on. You can turn
                        off notifications by visiting your mobile device’s “Settings” page.
                        Email. We may send you emails concerning our products and services,
                        as well as those of third parties. You may opt out of promotional
                        emails by following the unsubscribe instructions in the promotional
                        email itself.
                    </p>

                    <p>
                        <b>11. Prohibited Conduct</b>
                    </p>
                    <p>
                        BY USING THE SERVICE YOU AGREE NOT TO: use the Service for any
                        illegal purpose or in violation of any local, state, national, or
                        international law; harass, threaten, demean, embarrass, or otherwise
                        harm any other user of the Service; violate, or encourage others to
                        violate, any right of a third party, including by infringing or
                        misappropriating any third party intellectual property right;
                        interfere with security-related features of the Service, including
                        by: (i) disabling or circumventing features that prevent or limit
                        use or copying of any content; or (ii) reverse engineering or
                        otherwise attempting to discover the source code of any portion of
                        the Service except to the extent that the activity is expressly
                        permitted by applicable law; interfere with the operation of the
                        Service or any user’s enjoyment of the Service, including by: (i)
                        uploading or otherwise disseminating any virus, adware, spyware,
                        worm, or other malicious code; (ii) making any unsolicited offer or
                        advertisement to another user of the Service; (iii) collecting
                        personal information about another user or third party without
                        consent; or (iv) interfering with or disrupting any network,
                        equipment, or server connected to or used to provide the Service;
                        perform any fraudulent activity including impersonating any person
                        or entity, claiming a false affiliation, or accessing any other
                        Service account without permission; sell or otherwise transfer the
                        access granted under these Terms or any Materials (as defined in
                        Section 7) or any right or ability to view, access, or use any
                        Materials; or attempt to do any of the acts described in this
                        Section 11 or assist or permit any person in engaging in any of the
                        acts described in this Section 11.
                    </p>

                    <p>
                        <b>12. Digital Millennium Copyright Act</b>
                    </p>
                    <p>
                        DMCA Notification. We comply with the provisions of the Digital
                        Millennium Copyright Act applicable to Internet service providers
                        (17 U.S.C. §512, as amended). If you have an intellectual property
                        rights-related complaint about material posted on the Service, you
                        may contact our Designated Agent at the following address: Beyond
                        Budget. ATTN: Legal Department (Copyright Notification) Email:
                        admin@zero-budget.net. Any notice alleging that materials hosted by
                        or distributed through the Service infringe intellectual property
                        rights must include the following information: an electronic or
                        physical signature of the person authorised to act on behalf of the
                        owner of the copyright or other right being infringed; a description
                        of the copyrighted work or other intellectual property that you
                        claim has been infringed; a description of the material that you
                        claim is infringing and where it is located on the Service; your
                        address, telephone number, and email address; a statement by you
                        that you have a good faith belief that the use of the materials on
                        the Service of which you are complaining is not authorised by the
                        copyright owner, its agent, or the law; and a statement by you that
                        the above information in your notice is accurate and that, under
                        penalty of perjury, you are the copyright or intellectual property
                        owner or authorised to act on the copyright or intellectual property
                        owner’s behalf. Repeat Infringers. Beyond Budget will promptly
                        terminate the accounts of users that are determined by Beyond Budget
                        to be repeat infringers.
                    </p>

                    <p>
                        <b>13. Modification of these Terms.</b>
                    </p>
                    <p>
                        We reserve the right to change these Terms on a going-forward basis
                        at any time. Please check these Terms periodically for changes. If a
                        change to these Terms materially modifies your rights or
                        obligations, we may require that you accept the modified Terms in
                        order to continue to use the Service. Material modifications are
                        effective upon your acceptance of the modified Terms. Immaterial
                        modifications are effective upon publication. Except as expressly
                        permitted in this Section 13, these Terms may be amended only by a
                        written agreement signed by authorised representatives of the
                        parties to these Terms. Disputes arising under these Terms will be
                        resolved in accordance with the version of these Terms that was in
                        effect at the time the dispute arose.
                    </p>

                    <p>
                        <b>14. Term, Termination and Modification of the Service</b>
                    </p>
                    <p>
                        14.1 Term. These Terms are effective beginning when you accept the
                        Terms or first download, install, access, or use the Service, and
                        ending when terminated as described in Section 14.2.{" "}
                    </p>

                    <p>
                        14.2 Termination. If you violate any provision of these Terms, your
                        authorization to access the Service and these Terms automatically
                        terminate. In addition, Beyond Budget may, at its sole discretion,
                        terminate these Terms or your account on the Service, or suspend or
                        terminate your access to the Service, at any time for any reason or
                        no reason, with or without notice. You may terminate your account
                        and these Terms at any time by emailing us at admin@zero-budget.net.
                        Effect of Termination. Upon termination of these Terms: (a) your
                        license rights will terminate and you must immediately cease all use
                        of the Service; (b) you will no longer be authorised to access your
                        account or the Service; and (c) you must pay Beyond Budget any unpaid
                        amount that was due prior to termination. Modification of the
                        Service. Beyond Budget reserves the right to modify or discontinue the
                        Service at any time (including by limiting or discontinuing certain
                        features of the Service), temporarily or permanently, without notice
                        to you. Beyond Budget will have no liability for any change to the
                        Service or any suspension or termination of your access to or use of
                        the Service.
                    </p>

                    <p>15. Indemnity</p>
                    <p>
                        <b>
                            To the fullest extent permitted by law, you are responsible for
                            your use of the Service, and you will defend and indemnify Beyond
                            Budget and its officers, directors, employees, consultants,
                            affiliates, subsidiaries and agents (together, the “Beyond Budget
                            Entities”) from and against every claim brought by a third party,
                            and any related liability, damage, loss, and expense, including
                            reasonable attorneys’ fees and costs, arising out of or connected
                            with: (a) your unauthorised use of, or misuse of, the Service; (b)
                            your violation of any portion of these Terms, any representation,
                            warranty, or agreement referenced in these Terms, or any
                            applicable law or regulation; (c) your violation of any third
                            party right, including any intellectual property right or
                            publicity, confidentiality, other property, or privacy right; (d)
                            the nature or content of data processed by the Service; or (e) any
                            dispute or issue between you and any third party. We reserve the
                            right, at our own expense, to assume the exclusive defense and
                            control of any matter otherwise subject to indemnification by you
                            (without limiting your indemnification obligations with respect to
                            that matter), and in that case, you agree to cooperate with our
                            defense of those claims.
                        </b>
                    </p>

                    <p>
                        <b>16. Disclaimers; No Warranties</b>
                    </p>
                    <p>
                        THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE
                        SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. BEYOND
                        BUDGET DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                        IMPLIED, RELATING TO THE SERVICE AND ALL MATERIALS AND CONTENT
                        AVAILABLE THROUGH THE SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY
                        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
                        ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF
                        COURSE OF DEALING, USAGE, OR TRADE. BEYOND BUDGET DOES NOT WARRANT
                        THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR
                        CONTENT OFFERED THROUGH OR PROCESSED BY THE SERVICE, WILL BE
                        UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL
                        COMPONENTS, AND BEYOND BUDGET DOES NOT WARRANT THAT ANY OF THOSE
                        ISSUES WILL BE CORRECTED. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                        WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR BEYOND BUDGET ENTITIES OR
                        ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE
                        ANY WARRANTY REGARDING ANY OF THE BEYOND BUDGET ENTITIES OR THE
                        SERVICE THAT IS NOT EXPRESSLY STATED IN THESE TERMS. WE ARE NOT
                        RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE SERVICE AND YOUR
                        DEALING WITH ANY OTHER SERVICE USER. YOU UNDERSTAND AND AGREE THAT
                        YOU USE ANY PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK,
                        AND THAT WE ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY
                        (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION
                        WITH THE SERVICE) OR ANY LOSS, USE, OR DISCLOSURE OF DATA, INCLUDING
                        USER CONTENT. THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS
                        SECTION APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. Beyond Budget
                        does not disclaim any warranty or other right that Beyond Budget is
                        prohibited from disclaiming under applicable law.
                    </p>

                    <p>
                        <b>17. Limitation of Liability</b>
                    </p>
                    <p>
                        TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE BEYOND
                        BUDGET ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL,
                        SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR
                        LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT
                        OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO
                        ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON OR
                        AVAILABLE THROUGH THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT,
                        TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND
                        WHETHER OR NOT ANY BEYOND BUDGET ENTITY HAS BEEN INFORMED OF THE
                        POSSIBILITY OF DAMAGE. EXCEPT AS PROVIDED IN SECTION 18 AND TO THE
                        FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE BEYOND
                        BUDGET ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO
                        THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE SERVICE OR
                        OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR
                        OTHERWISE, IS LIMITED TO THE GREATER OF: (A) THE AMOUNT YOU HAVE
                        PAID TO BEYOND BUDGET FOR ACCESS TO AND USE OF THE SERVICE IN THE 12
                        MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO CLAIM; OR
                        (B) $10. EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A
                        LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF
                        DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE
                        PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT
                        OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE
                        PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF
                        THESE TERMS. THE LIMITATIONS IN THIS SECTION 17 WILL APPLY EVEN IF
                        ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                    </p>

                    <p>
                        <b>18. Dispute Resolution and Arbitration</b>
                    </p>
                    <p>
                        1 Generally. In the interest of resolving disputes between you and
                        Beyond Budget in the most expedient and cost effective manner, subject
                        to all applicable laws and except as described in Section 18.2, you
                        and Beyond Budget agree that every dispute arising in connection with
                        these Terms will be resolved by binding arbitration. Arbitration is
                        less formal than a lawsuit in court. Arbitration uses a neutral
                        arbitrator instead of a judge or jury, may allow for more limited
                        discovery than in court, and can be subject to very limited review
                        by courts. Arbitrators can award the same damages and relief that a
                        court can award. This agreement to arbitrate disputes includes all
                        claims arising out of or relating to any aspect of these Terms,
                        whether based in contract, tort, statute, fraud, misrepresentation,
                        or any other legal theory, and regardless of whether a claim arises
                        during or after the termination of these Terms. YOU UNDERSTAND AND
                        AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND BEYOND BUDGET ARE
                        EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A
                        CLASS ACTION. If you reside in a country (for example, a member
                        state of the European Union) with laws that give consumers the right
                        to bring disputes in their local courts, this Section 18 does not
                        affect those requirements.
                    </p>

                    <p>
                        2. Exceptions. Despite the provisions of Section 18.1, nothing in
                        these Terms will be deemed to waive, preclude, or otherwise limit
                        the right of either party to: (a) bring an individual action in
                        small claims court; (b) pursue an enforcement action through the
                        applicable federal, state, or local agency if that action is
                        available; (c) seek injunctive relief in a court of law in aid of
                        arbitration; or (d) to file suit in a court of law to address an
                        intellectual property infringement claim.
                    </p>

                    <p>
                        3. Enforceability. If Section 18 is found to be unenforceable then
                        the entirety of this Section 18 will be null and void and, in that
                        case, exclusive jurisdiction and venue described in Section 19.2
                        will govern any action arising out of or related to these Terms.
                    </p>

                    <p>
                        <b>19. No professional advice</b>
                    </p>

                    <p>
                        Beyond Budget may provide you with personalized services relating to financial health (such as
                        measures and analyses of various financial indicators). You hereby acknowledge and agree that
                        such advice does not constitute professional financial, tax, accounting, investment or any other
                        professional advice. The Beyond Budget makes no representations or warranties of any kind related
                        to that. In no event shall Beyond Budget be liable for any damages caused to you or a third person
                        arising out of your use of such financial advice.
                    </p>

                    <p>
                        <b>20. Miscellaneous</b>
                    </p>
                    <p>
                        1 General Terms. These Terms, together with the Privacy Policy and
                        any other agreements expressly incorporated by reference into these
                        Terms, are the entire and exclusive understanding and agreement
                        between you and Beyond Budget regarding your use of the Service. You
                        may not assign or transfer these Terms or your rights under these
                        Terms, in whole or in part, by operation of law or otherwise,
                        without our prior written consent. We may assign these Terms at any
                        time without notice or consent. The failure to require performance
                        of any provision will not affect our right to require performance at
                        any other time after that, nor will a waiver by us of any breach or
                        default of these Terms, or any provision of these Terms, be a waiver
                        of any subsequent breach or default or a waiver of the provision
                        itself. Use of section headers in these Terms is for convenience
                        only and will not have any impact on the interpretation of any
                        provision. Throughout these Terms the use of the word “including”
                        means “including but not limited to”. If any part of these Terms is
                        held to be invalid or unenforceable, the unenforceable part will be
                        given effect to the greatest extent possible, and the remaining
                        parts will remain in full force and effect.
                    </p>

                    <p>
                        2. Governing Law. These Terms are governed by the laws of New
                        Zealand without regard to conflict of law principles. You and Beyond
                        Budget submit to the personal and exclusive jurisdiction of the
                        country’s courts.
                    </p>

                    <p>
                        3. Privacy Policy. Please read the Beyond Budget privacy policy
                        carefully for information relating to our collection, use, storage,
                        disclosure of your personal information. Beyond Budget’s Privacy
                        Policy is incorporated by this reference into, and made a part of,
                        these Terms.
                    </p>

                    <p>
                        4. Additional Terms. Your use of the Service is subject to all
                        additional terms, policies, rules, or guidelines applicable to the
                        Service or certain features of the Service that we may post on or
                        link to from the Service (the “Additional Terms”). All Additional
                        Terms are incorporated by this reference into, and made a part of,
                        these Terms.
                    </p>

                    <p>
                        5. Consent to Electronic Communications. By using the Service, you
                        consent to receiving certain electronic communications from us as
                        further described in our Privacy Policy. Please read our Privacy
                        Policy to learn more about our electronic communications practices.
                        You agree that any notices, agreements, disclosures, or other
                        communications that we send to you electronically will satisfy any
                        legal communication requirements, including that those
                        communications be in writing.
                    </p>

                    <p>
                        6. No Support. We are under no obligation to provide support for the
                        Service. In instances where we may offer support, the support will
                        be subject to published policies. International Use. The Service is
                        operated from New Zealand. We make no representation that the
                        Service is appropriate or available for use outside of New Zealand.
                        Access to the Service from countries or territories or by
                        individuals where such access is illegal is prohibited.
                    </p>
                </Card.Body>
            </Card>
        </div>
    );
}
