import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function PrivacyPolicy() {
    return (
        <div className="App m-4">
            <Card>
                <Card.Body>

                    <p><b>Privacy Policy</b></p>
                    <p><b>Last modified October 24, 2021 with an effective date of October 27, 2021</b></p>

                    <p>This privacy policy ("Policy") informs you of practices when handling your Information through
                        the Services (both as defined below). In this Policy, "Beyond Budget", "we" or "us" refers to Beyond
                        Budget. We are the data controller under the applicable privacy laws.</p>

                    <p>For the purpose of this Policy, "Information" means any information relating to an identified or
                        identifiable individual. This includes Information you provide or generated when you use: (a)
                        our app. When you use the Services, you accept and understand we collect, process, use and store
                        your Information as described in this Policy. If you do not agree with this Policy, you must not
                        use any of the Services. If you change your mind in the future, you must stop using the Services
                        and you may exercise your rights in relation to your Information as set out in this Policy.</p>

                    <p><b>Information we collect</b></p>

                    <p>We will collect and use the following Information about you:</p>

                    <p>Identifiers, such as your IP address, device ID, and device information (such as model, brand and
                        operating system).</p>

                    <p>Registration information : name, email, phone number, a password, your billing address and
                        transaction information, tax identification number, mobile phone number and invoice address
                        ("Payment Information").</p>

                    <p>Information collected via voluntary activities: for example, when you communicate with us via
                        email, or participate in surveys, we ask you to provide your email address and any Information
                        you choose to provide to allow us to assist you. The Information we collect from you varies
                        depending on the survey, but we typically ask you questions about your interests and habits,
                        your opinion about different products and services and what you‘d like to be offered in the
                        future. We also collect your ratings, opinions, preferences, questions, pictures, and responses
                        associated with that survey.
                    </p>


                    <p><b>Information we automatically collect or is generated about you when use the Services</b></p>

                    <p>Geolocation information, such as your GPS information when recording a transaction (when feature
                        is enabled explicitly by you). Where required and enabled we will obtain your consent prior to
                        collecting such information.</p>

                    <p>Cookies: we use cookies and other similar technologies ("Cookies") to enhance your experience
                        when using the Services. For more information about our Cookies policy, see below How We Use
                        Cookies and Similar Technologies section.</p>

                    <p>Information you generate when using the Services: You may provide Information as part of your use
                        of the Services, including any information you provide when sending messages through the
                        Services. Also, if you choose to share and collaborate on a task with your family or friends, we
                        will collect the email address of your family or friends.</p>

                    <p>Information regarding your use of the Services , such as app use information, interactions with
                        our sharing group, and transaction records.</p>

                    <p>Information we receive from third party platforms: when you register through a third party
                        account (such as Facebook or Google) we receive Information which may include your username,
                        email address, and profile picture.
                        Information from platforms our Services relies on , such as for transaction information and
                        payment verification.</p>

                    <p><b>Connecting your bank.</b></p>

                    <p>If you choose to connect your bank account(s) to your Beyond Budget account, you authorise Beyond
                        Budget to retrieve transaction information from your linked bank account securely. Your banking
                        username and password are never stored by or disclosed to Beyond Budget. This authorisation allows
                        Beyond Budget (as your agent) to access historical data generated before you link your bank
                        account to your Beyond Budget App, as well as constantly refreshing that data (e.g. by way of a
                        daily file update or real-time access to transaction data) for future transactions. By
                        connecting your bank accounts to the Beyond Budget app, Beyond Budget has 'read only' access to
                        provide our services and has no access or ability to process payments. You can help us keep your
                        information secure by ensuring that any banking credentials, username, or password are kept
                        strictly personal to you and not be made available to any other person.
                    </p>

                    <p>
                        We use Akahu to connect to New Zealand banks. When using the service provided by Akahu you also
                        need to accept their end user terms and privacy notice
                    </p>

                    <p>
                        Akahu end user terms - <Link to={{pathname: "https://www.akahu.io/end-user-terms"}}
                                                     target="_blank">https://www.akahu.io/end-user-terms</Link>
                    </p>

                    <p>
                        Akahu privacy notice - <Link to={{pathname: "https://www.akahu.io/privacy-notice"}}
                                                     target="_blank">https://www.akahu.io/privacy-notice/</Link>
                    </p>

                    <p><b>Children</b></p>

                    <p>Our Services are not targeted at children, and we do not knowingly collect Information from
                        children under the age of 13. If you learn that a child has provided us with Information in
                        violation of this Policy, please contact us as indicated below.</p>

                    <p><b>How we use your personal information</b></p>

                    <p>We use your Information to: Provide you with the Services. We will use your Information to
                        perform our contractual obligation towards you to allow you to create an account and use the
                        Services. The Information we process when doing so includes your registration information,
                        information you provide to us when using the Services, identifiers, information you generate
                        when using the Services, and information regarding your use of the Services such as transaction
                        information. We also use your Information when you activate certain features of the Services,
                        such as your Geolocation information when you use the “Capture my location when adding
                        transaction” feature. If you subscribe to our services at the end of the free trial we will also
                        use your Payment Information for payment processing purposes as well as sales tax collection and
                        reporting as required by law.</p>

                    <p>The Personal Data may be freely provided by the User, or, in case of Usage Data, collected
                        automatically when using this Application.</p>

                    <p>All Data requested by this Application is mandatory and failure to provide this Data may make it
                        impossible for this Application to provide its services. In cases where this Application
                        specifically states that some Data is not mandatory, Users are free not to communicate this Data
                        without any consequences on the availability or the functioning of the service.</p>

                    <p>Or</p>

                    <p>Improve and monitor the Services. It is in our legitimate interests to improve our Services for
                        our customers. When doing so, we may collect information we automatically collect or is
                        generated about you when you use the Services, as well as non-personal information about your
                        device such as device manufacturer, model and operating system, and the amount of free space on
                        your device.</p>

                    <p>Provide you with support and to respond to your requests or complaints. If you reach out to us
                        for support, we will use your Information to respond to and resolve your queries and complaints
                        and facilitate support (e.g. subscription has not renewed, forgotten account, etc...). When
                        doing so, we perform our contractual obligation towards you. The Information we process when
                        doing so includes your registration information, your identifiers, and any other information
                        about you collected via our customer support channels.</p>

                    <p>Conduct analytics. It is in our legitimate interests to analyse the use of, and any other
                        interaction or interest in our Services. When doing so we will process information we
                        automatically collect or is generated about you when you use the Services to create anonymised
                        and aggregated data regarding your App usage.</p>

                    <p>Send you newsletters or push notifications about product news, tips and tricks, daily reminders
                        that may be of interest to you. We will send you emails or push notifications with daily
                        reminders, opt-in newsletters with product news, and tips and tricks to use our Services. When
                        doing so, we process your registration information. Your consent can be withdrawn at any time by
                        following the unsubscribe mechanism at the bottom of each communication.</p>

                    <p>Prevent fraud, defend Beyond Budget against legal claims or disputes, enforce our terms and to
                        comply with our legal obligations. It is in our legitimate interest to protect our interests by
                        (1) monitoring the use of the Services to detect fraud or any other user behaviour which
                        prejudices the integrity of our Services, (2) taking steps to remedy aforementioned fraud and
                        behaviour, (3) defending ourselves against legal claims or disputes, and (4) enforcing our terms
                        and policies. When doing so, we will process the Information relevant in such a case, including
                        information you provide us, information we automatically collect about you, and information
                        which is provided to us by third parties.</p>

                    <p>Conduct surveys. From time to time, we may ask you to participate in surveys we conduct which are
                        in our legitimate interest because they help us understand our user base and improve the
                        Services. If you participate, we process your registration information and any other information
                        collected through the survey questions.</p>


                    <p><b>How we use cookies and similar technologies</b></p>
                    <p>Cookies are small files of letters and numbers that we store on your browser or the hard drive of
                        your computer. They contain information that is transferred to your computer‘s hard drive.</p>

                    <p>Our Services uses Cookies to collect information about your browsing activities and to
                        distinguish you from other users of our Services. This aids your experience when you use our
                        Services and also allows us to improve the functionality of our Services.</p>

                    <p><b>We use the following cookies:</b></p>

                    <p>Strictly necessary cookies: Some cookies are strictly necessary to make our Services available to
                        you; for example, to perform your login functionality and for user authentication and security.
                        We cannot provide you with the Services without this type of cookie.</p>

                    <p>Functional cookies : These are used to recognise you when you return to our Website. This enables
                        us to personalise our content for you, greet you by name and remember your preferences.</p>

                    <p>Analytical or performance cookies. We also use cookies for analytics purposes in order to
                        operate, maintain, and improve our Services. We use third party analytics providers, including
                        Google Analytics and Firebase. To help us understand how users engage with the Services. Google
                        Analytics uses first-party cookies to track user interactions which helps show how users use our
                        Services. This information is used to compile reports and to help us improve our Services. The
                        reports disclose Website trends without identifying individual visitors.</p>

                    <p>You can block cookies by setting your internet browser to block some or all cookies. However, if
                        you use your browser settings to block all cookies (including essential cookies) you may not be
                        able to use our Services.</p>

                    <p>Except for essential cookies, all cookies will expire after a maximum of 2 years.</p>

                    <p><b>Who we share your personal information with</b></p>
                    <p>We share your Information with selected third parties, including:</p>

                    <p>Other users who will see your profile information and any other information you choose to share
                        with them through the Services.</p>

                    <p>Vendors and service providers we rely on for the provision of the Services , for example:</p>

                    <p>Cloud service providers who we rely on for data storage, including Google Cloud Platform and
                        Firebase who are based in the U.S. and hosted primarily in their datacenter in Sydney,
                        Australia.</p>

                    <p>Customer support solution providers , who help us manage and respond to our customer questions
                        and complaints. This includes Zendesk Inc., which is based in the U.S. and which hosts our
                        customer support function; and

                        Analytics providers. We work with a number of analytics, segmentation and mobile measurement
                        service providers who help us understand our user base. This includes Google LLC, which is based
                        in the U.S. You can learn about Google‘s practices by going to
                        https://www.google.com/policies/privacy/partners/, and opt-out of them by downloading the Google
                        Analytics opt-out browser add-on, available at https://tools.google.com/dlpage/gaoptout.</p>

                    <p>Third Parties Service Integrations. When you connect third party apps to the Apps, you authorize
                        us to share designated information and data created and/or uploaded by you to our server with
                        these third party services of your choice on your behalf.</p>

                    <p>Communications platform providers , who help us manage and send newsletters to you in relation to
                        the Services. This includes SendGrid, Mailgun and MailChimp which are based in the U.S.</p>

                    <p>Payment processors , such as Google Payments. This payment processor is responsible for the
                        processing of your Information, and may use your Information for their own purposes in
                        accordance with their privacy policies.</p>

                    <p>Law enforcement agencies, public authorities or other judicial bodies and organisations. We
                        disclose Information if we are legally required to do so, or if we have a good faith belief that
                        such use is reasonably necessary to comply with a legal obligation, process or request; enforce
                        our terms of service and other agreements, policies, and standards, including investigation of
                        any potential violation thereof; detect, prevent or otherwise address security, fraud or
                        technical issues; or protect the rights, property or safety of us, our users, a third party or
                        the public as required or permitted by law (including exchanging information with other
                        companies and organisations for the purposes of fraud protection).</p>

                    <p>Change of corporate ownership. If we are involved in a merger, acquisition, bankruptcy,
                        reorganisation, partnership, asset sale or other transaction, we may disclose your Information
                        as part of that transaction.</p>

                    <p>Although we have in place security measures to maintain the privacy and integrity of your
                        Information, the transmission of Information via the internet is not completely secure. We may
                        also take extra steps to protect your Information and minimise the Information we process. For
                        example, when we store your Information, we use Firebase Firestore which uses its own security
                        mechanism to safeguard the data and when we send or receive your Information, it is encrypted
                        with TLS 1.1 or above. Additionally, we are not responsible for how third party integration
                        services may collect, use or share the Information you send from the Apps. Please review the
                        privacy policy of such third party integration partners before connecting those services to the
                        Apps.</p>

                    <p><b>Where we store your information</b></p>
                    <p>Your Information will be processed by our employees in New Zealand. All information is stored on
                        Google Cloud Platform and Firebase who are based in the U.S. and hosted primarily in their
                        datacenter in Sydney, Australia. All data is encrypted at rest using AES-256. More information
                        can be found on <Link
                            to={{pathname: "https://cloud.google.com/firestore/docs/server-side-encryption"}}
                            target="_blank">https://cloud.google.com/firestore/docs/server-side-encryption</Link>.
                    </p>

                    <p><b>How long we store your information</b></p>
                    <p>Your Information is kept for as long as necessary to achieve the purposes set out above.
                        Generally, it is stored for as long as you are registered and using our Services or until the
                        user asks us to delete the information.</p>

                    <p><b>The rights of Users</b></p>
                    <p>Users have the right, at any time, to know whether their Personal Data has been stored and can
                        consult the Data Controller to learn about their contents and origin, to verify their accuracy
                        or to ask for them to be supplemented, cancelled, updated or corrected, or for their
                        transformation into anonymous format or to block any data held in violation of the law, as well
                        as to oppose their treatment for any and all legitimate reasons. Requests should be sent to the
                        Data Controller at the contact information set out above.</p>

                    <p><b>This Application does not support “Do Not Track” requests.</b></p>
                    <p>To determine whether any of the third-party services it uses honor the “Do Not Track” requests,
                        please read their privacy policies.</p>

                    <p><b>Changes</b></p>
                    <p>If we make any changes to this Policy, we will post the updated Policy here and notify our users
                        through the Services and/or newsletters. Please check this page frequently to see any updates or
                        changes to this Policy.</p>

                </Card.Body>
            </Card>
        </div>
    );
}
